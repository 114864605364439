


































































































































import { Component, Vue } from 'vue-property-decorator'
import AddCheckout from '@/components/AddCheckout.vue'
import AddUnstakeLongDeposit from '@/components/AddUnstakeLongDeposit.vue'
import {
	getAccount,
	getAccounts,
	getAPIConfiguration,
	getLongDepositInfo,
	getSpotPairs, getStaticLaunchpadConf,
	LongDeposit, TradePair
} from '@/clients/cpinblocks'
import { Account } from '@/models/Account'
import type { Currency } from '@/types'
import { Conf } from '@/models/Conf'
import { formatFixedDecimalsNoUselessZero, guessAllTokensType, isProd, isStaging } from '@/utils'
import { BigNumber } from 'bignumber.js'
import { TokenType } from '@/types'
import { TypedJSON } from 'typedjson'

@Component({
	methods: {formatFixedDecimalsNoUselessZero},
	components: {
		AddCheckout,
		AddUnstakeLongDeposit,
	},
})
export default class LongDepositView extends Vue {
	private allAccounts: Account[] = []
	private checkoutAmount = new BigNumber(100)
	private checkoutShow = false
	private checkoutToken: Currency | null = null
	private checkoutTokenType: TokenType | null = null
	private conf: Conf | null = null
	private headers = [
		{
			align: 'left',
			sortable: false,
			value: 'currency',
			text: 'Currency',
			width: '10%',
		},
		{
			align: 'right',
			sortable: false,
			value: 'initialCapital',
			text: 'Initial capital',
			width: '20%',
		},
		{
			align: 'right',
			sortable: false,
			value: 'totalCapital',
			text: 'Current capital',
			width: '20%',
		},
		{
			align: 'right',
			sortable: false,
			value: 'earnings',
			text: 'Earnings',
			width: '20%',
		},
		{
			align: 'center',
			sortable: false,
			value: 'actions',
			text: 'Actions',
			width: '30%',
		},
	]
	private loading = false
	private longDepositAccount: LongDeposit[] = []
	private pairs: TradePair[] = []
	private project: any[] = []
	private selectedAccount: Account | null = null
	private showInformation = false
	private showUnstakeDialog = false

	private async mounted (): Promise<void> {
		this.conf = await getAPIConfiguration()
		this.pairs = await getSpotPairs()
		this.project = await getStaticLaunchpadConf()
		await this.refresh()
		this.loading = false
	}

	private clickOnRow (item: any): void {
		if (this.accountExists(item)) {
			this.$router.push(`/longdeposit/${item.currency.toLowerCase()}`)
		}
	}

	private checkoutEnabled (token: Currency): boolean {
		for (const tp of this.pairs) {
			if (tp.productCurrency === token &&
				tp.productType === 'LONG' &&
				tp.unitPriceCurrency === 'IBXE' &&
				tp.unitPriceType === 'MAIN' &&
				tp.checkout === 'ENABLED') {
				return true
			}
		}
		return false
	}

	private accountExists(item: any): boolean {
		return item.deposits.length > 0
	}

	private async refresh (): Promise<void> {
		this.loading = true
		this.longDepositAccount = []
		const conf = await getAPIConfiguration(this.$store.state.jwt)
		if (conf === null) {
			return
		}
		this.allAccounts = await getAccounts(this.$store.state.jwt, false)
		const spotPairs = await getSpotPairs()
		const tokenList = guessAllTokensType(conf, this.allAccounts, spotPairs, [])
		const notEmptyAccounts: Currency[] = []
		const emptyAccounts: Currency[] = []
		const possibleAccounts: Currency[] = []
		for (const token of tokenList.tokens) {
			// Temporary patch to hide BOBY account
			if (token.currency === 'BOBY') {
				continue
			}
			if (token.types.indexOf('LONG') >= 0) {
				const bal = this.balance(token.currency as Currency, 'LONG')
				if (!bal) {
					possibleAccounts.push(token.currency as Currency)
				} else if (new BigNumber(bal).gt(0)) {
					notEmptyAccounts.push(token.currency as Currency)
				} else {
					emptyAccounts.push(token.currency as Currency)
				}
			}
		}
		for (const currency of notEmptyAccounts) {
			// Temporary patch to hide BOBY account
			if (currency === 'BOBY') {
				continue
			}
			this.longDepositAccount.push(await getLongDepositInfo(this.$store.state.jwt, currency))
		}
		for (const currency of emptyAccounts) {
			// Temporary patch to hide BOBY account
			if (currency === 'BOBY') {
				continue
			}
			this.longDepositAccount.push(await getLongDepositInfo(this.$store.state.jwt, currency))
		}
		for (const currency of possibleAccounts) {
			// Temporary patch to hide BOBY account
			if (currency === 'BOBY') {
				continue
			}
			const serializer = new TypedJSON(LongDeposit)
			this.longDepositAccount.push(serializer.parse({
				accountId: '',
				currency: currency as Currency,
				type: 'LONG',
				totalCapital: new BigNumber(0),
				totalEarnings: new BigNumber(0),
				totalBalance: new BigNumber(0),
				deposits: []
			}) as LongDeposit)
		}
		this.selectedAccount = null
		this.loading = false
	}

	private unstakeVisible (item: any): boolean {
		return new BigNumber(item.totalBalance).gt(0)
	}

	private unstakeEnabled (item: Account): boolean {
		if (!this.conf?.internalTransferable) {
			return false
		}
		for (const o of this.conf.internalTransferable) {
			if (o.transactionType === 'UNSTAKE') {
        let bal = this.balance(item.currency as Currency, item.type as TokenType)
        if (bal === null) {
          bal = new BigNumber("0")
        }
				return o.allowedCurrencies ? o.allowedCurrencies?.indexOf(item.currency) >= 0 && bal.gt(0) : false
			}
		}
		return false
	}

	private async onUnstake (item: any): Promise<void> {
		this.selectedAccount = (await getAccount(this.$store.state.jwt, item.currency, item.type))[0]
		this.showUnstakeDialog = true
	}

	async onSuccessUnstake (): Promise<void> {
		this.showUnstakeDialog = false
		await this.refresh()
	}

	private async onBuy (item: any): Promise<void> {
		this.checkoutShow = true
		this.checkoutToken = item.currency
		this.checkoutTokenType = item.type
	}

	private async onBuyClosed (item: any): Promise<void> {
		this.checkoutShow = false
		this.checkoutToken = null
		this.checkoutTokenType = null
	}

	private balance (currency: Currency, type: TokenType): BigNumber | null {
		for (const a of this.allAccounts) {
			if (a.currency === currency && a.type === type) {
				return a.balance
			}
		}
		return null
	}

}
